.filters {
	margin: 30px 0 15px;
	display: flex;
	flex-wrap: wrap;
	gap: 10px 20px;

	label {
		display: flex;
		align-items: center;
		gap: 5px;
		font-size: 1.6rem;
		font-weight: 500;
		cursor: pointer;
		user-select: none;
	}
}
