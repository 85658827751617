@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');
@import './variables';
@import './help';
@import './components/button';
@import './components/filters';

html {
	font-size: 10px;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
	outline: none;
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
}
a {
	text-decoration: none;
}
p {
	margin: 0;
	padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
	color: #fff;
	line-height: 1.5;
	margin: 10px 0;
}
ul {
	margin: 10px 0;
	padding-left: 15px;
	li {
		font-size: 1.6rem;
		line-height: 1.75;
	}
}
h1 {
	font-size: 3.2rem;
}
h2 {
	font-size: 2.6rem;
}
h3 {
	font-size: 2.2rem;
}
h4 {
	font-weight: 600;
	font-size: 1.8rem;
}
.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: $bgc-main;
	color: $c-text-main;
}
.main {
	padding-top: 50px;
	position: relative;
	z-index: 2;
	flex: 1 1 100%;
}
.container {
	display: flex;
	flex-direction: column;
	max-width: 900px;
	margin: 0 auto;
	padding: 10px;
}
.game-info {
	padding: 20px 0;
	display: flex;
	gap: 30px;

	.left-info {
		display: flex;
		flex-direction: column;
		gap: 10px;
		flex: 0 0 65%;

		&:hover .header-img-game {
			transform: scale(1.03);
			box-shadow: $shadow-low;
		}
	}
	.right-info {
		margin-top: 55px;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.price {
		font-size: 1.8rem;
		font-weight: 500;
		color: $c-text-light;
	}
}

.bg-game {
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: -1;
	object-fit: cover;
	background-size: cover !important;
	background-position: center !important;
}
.header-img-game {
	width: 100%;
	border-radius: $bor-r5;
	transition: all 0.2s ease-in-out;
}
.button-wrap {
	position: relative;
	display: flex;
	align-items: center;
	gap: 20px;
}
.loader {
	position: relative;
	height: 40px;
	opacity: 0;
	transform: scale(1.2);
	transition: all 0.2s ease-in-out;
}
.loader.active {
	display: inline-block;
	opacity: 1;
	transition: all 0.2s ease-in-out;
}

@media (max-width: 850px) {
	.game-info {
		flex-direction: column;

		.right-info {
			margin-top: 5px;
		}
	}
}
